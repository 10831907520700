@import "../../../../assets/sass/theme.scss";
@import "../../common/common.scss";

.table-view-container {
    margin-left: 0.5rem;
    max-height: 85vh;
    max-width: 85vw;
    .viewed-table {
        margin: 0.25rem 0.75rem;
        td {
            border: 2px solid #8D8D8D;
            min-width: 80px;
            padding: 0.5rem 1rem;
        }
        td:empty::after {
            content: "\00a0";
        }
        td.table-header {
            font-weight: bold;
            background-color: #272b30;
        }
    }
}

.close-modal {
    position: fixed;
    top: 10px;
    right: 13px;
    color: #fff;
    &:hover {
        color: $lighter-5;
        cursor: pointer;
    }
}

.table-view-scrollable-content {
    margin: 2.25rem 1rem;
    max-height: 65vh;
}
